import React from "react"
import { bps } from "@src/build/constants"
import useLayoutEffect from "@react-hook/passive-layout-effect"

// sm: "(max-width: 768px)",
// md: "(min-width: 768px)",
// lg: "(min-width: 1024px)",
// xl: "(min-width: 1280px)",

export function useMediaQuery() {
  const [activeMq, setActiveMq] = React.useState(undefined)

  function setBpHandler(e) {
    const mq = Object.keys(bps).filter((key, i) => {
      if (bps[key].includes(e.media) && e.matches) return key
    })[0]

    if (activeMq !== mq) {
      setActiveMq(mq)
    }
  }

  useLayoutEffect(() => {
    const mms = Object.keys(bps).map((key, i) => window.matchMedia(bps[key]))

    mms.forEach(mm => {
      setBpHandler(mm)
      mm.addListener(setBpHandler)
    })

    return () => {
      mms.forEach(mm => mm.removeListener(setBpHandler))
    }
  }, [])

  return activeMq
}
