import { LazyLoadImage } from "react-lazy-load-image-component"
import React from "react"
import clsx from "clsx"
import { imgSourcesHandler } from "@src/helpers"
import parse from "html-react-parser"

function Img({
  image,
  mobileMaxHeight,
  blendMode,
  visibleByDefault = false,
  paddedCaption,
  className = "",
  classNames = "",
  oldSchool,
  layoutFill,
  upTo,
  widthFull,
  fullHeight,
}) {
  const [loaded, setLoaded] = React.useState(visibleByDefault)
  const alt = image?.altText || image?.node?.altText
  const title = image?.title || image?.node?.title
  const caption = image?.caption || image?.node?.caption
  const sizes = image?.mediaDetails?.sizes || image?.node?.mediaDetails?.sizes

  // set a max number of sizes for images: ie - preview images
  if (upTo) {
    const upToIndex = sizes?.findIndex(item => item.name === upTo)

    if (upToIndex !== null && upToIndex !== undefined && upToIndex !== -1) {
      sizes.splice(upToIndex + 1)
    }
  }

  const defaultImage = sizes ? sizes[sizes.length - 1] : undefined
  const url =
    defaultImage?.sourceUrl || image?.mediaItemUrl || image?.node?.mediaItemUrl
  const width =
    defaultImage?.width ||
    image?.mediaDetails?.width ||
    image?.node?.mediaDetails?.width
  const height =
    defaultImage?.height ||
    image?.mediaDetails?.height ||
    image?.node?.mediaDetails?.height
  const contAspectRatio = (height / width) * 100
  const isSVG = url?.endsWith("svg")
  const outerContClasses = clsx([
    "w-full transition-opacity duration-500 ease-in-out",
    loaded ? "opacity-100" : " opacity-0",
    layoutFill && "h-full",
  ])
  const innerContClasses = clsx([
    "w-full relative mx-auto",
    (layoutFill || fullHeight) && "h-full",
  ])
  const imgClasses = clsx([
    "w-full max-h-full select-none",
    !widthFull && "object-cover",
    fullHeight && "h-full",
    blendMode === "multiply" && "mix-blend-multiply",
    contAspectRatio && "absolute",
    layoutFill &&
      "min-h-full max-w-none top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2",
    !layoutFill && "top-0 left-0 h-auto",
  ])

  if (oldSchool)
    return (
      <LazyLoadImage
        src={url ? url : image?.originalSrc}
        width={width}
        height={height}
        threshold={300}
        afterLoad={() => setLoaded(true)}
        alt={alt}
        className={`select-none ${className} ${classNames} ${
          blendMode === "multiply" ? "mix-blend-multiply" : ""
        }`}
      />
    )

  return (
    <figure className={`${className} ${classNames} ${outerContClasses}`}>
      <div
        className={innerContClasses}
        style={{ paddingBottom: `${contAspectRatio}%` }}
      >
        <picture>
          {sizes && imgSourcesHandler(sizes)}
          <LazyLoadImage
            src={url ? url : image?.originalSrc}
            alt={alt || title}
            width={width}
            height={height}
            title={title}
            className={imgClasses}
            threshold={300}
            afterLoad={() => setLoaded(true)}
            visibleByDefault={visibleByDefault}
          />
        </picture>
      </div>
      {caption && caption !== "" && (
        <figcaption
          className={`text-left py-10 pl-10${paddedCaption ? "" : " md:pl-0"}`}
        >
          {parse(caption)}
        </figcaption>
      )}
      <noscript>
        <img src={url ? url : image?.originalSrc} alt={alt} />
      </noscript>
    </figure>
  )
}

export default Img
