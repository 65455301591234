import React from "react"
import { useEmblaCarousel } from "embla-carousel/react"
import styled, { css } from "styled-components"

export default function MediaCarouselMobile({
  items,
  className,
  children,
  dragFree,
  overflow,
}) {
  const [viewportRef, embla] = useEmblaCarousel({
    containScroll: "keepSnaps",
    slidesToScroll: 1,
    dragFree,
  })
  const [prevBtnEnabled, setPrevBtnEnabled] = React.useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = React.useState(false)
  const [selectedIndex, setSelectedIndex] = React.useState(1)

  /**
    tweaked the onClick navigation to avoid
    having the spanner list elements (in {children})
    counted in the native plugin navigation
  */
  const scrollPrev = React.useCallback(() => {
    if (embla && selectedIndex > 1) {
      embla.scrollTo(selectedIndex - 1)
      setSelectedIndex(selectedIndex - 1)
    }
  }, [embla, selectedIndex])

  const scrollNext = React.useCallback(() => {
    if (embla && selectedIndex < items.length) {
      embla.scrollTo(selectedIndex + 1)
      setSelectedIndex(selectedIndex + 1)
    }
  }, [embla, selectedIndex])

  const onSelect = React.useCallback(() => {
    if (!embla) return
    setSelectedIndex(selectedIndex)
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
  }, [embla, selectedIndex])

  React.useEffect(() => {
    if (!embla) return

    onSelect()
    embla.on("select", onSelect)
  }, [embla, onSelect])

  if (!items.length || items.length === 0) return null

  return (
    <div
      ref={viewportRef}
      className={`embla relative col-span-12 overflow-x-hidden ${
        className ?? ""
      }`}
    >
      {children}
      <StyledButton
        type="button"
        onClick={scrollPrev}
        enabled={prevBtnEnabled}
        className="absolute w-1/3 top-0 left-0 h-full"
      />
      <StyledButton
        type="button"
        onClick={scrollNext}
        enabled={nextBtnEnabled}
        className="absolute w-1/3 top-0 right-0 h-full"
      />
    </div>
  )
}

const StyledButton = styled.button`
  pointer-events: none;

  @media (pointer: fine) {
    pointer-events: auto;
  }
`
